import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './pages/landing/Container';
import About from './pages/about/Container';
import './layout.scss';
import './components/styles.scss';
import Navigation from './components/Nav';
import Footer from './components/Footer';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { UserSelectionProvider } from './UserSelectionContext';
import OrderSample from './pages/order-sample/Container';


const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <UserSelectionProvider>
    <Router>
      <div id="content">
            <Navigation />
      <Routes>
        <Route path="/" element={<Landing />} />
              <Route path="/about" element={<About />} />
              <Route path="/order-sample" element={<OrderSample />} />
      </Routes>
      
      </div>
        <Footer />  
        </Router>
        </UserSelectionProvider>
      </ThemeProvider>
  );
  
};

export default App;
