import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from './assets/logo.svg';
import Hamburger from 'hamburger-react';
import { shop_url } from '../utils';

type MobileMenuProps = {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
}

const MobileMenu = ({ isOpen, setOpen }: MobileMenuProps) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={`mobile-menu ${isOpen ? 'open' : ''}`} onClick={handleClose}>
            <div className="cross" onClick={handleClose}>
                <span></span>
                <span></span>
            </div>
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to={shop_url} target="_blank">Shop</Link></li>
            </ul>
        </div>
    );
}

const Navigation: React.FC = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <nav>
            <div className="logo">
                <Link to="/">
                    <img src={Logo} alt="logo of LAX" />
                </Link>
            </div>
            <MobileMenu isOpen={isOpen} setOpen={setOpen} />
            <div className="links">
                <ul>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to={shop_url} target="_blank">Shop</Link></li>
                </ul>
            </div>
            <div className="hamburger">
                <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
        </nav>
    );
}

export default Navigation;