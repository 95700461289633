import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormControl, TextField } from '@mui/material';
import './styles.scss';
import Button from '../../components/Button';
import { createLead, Lead } from '../../api/createLead';
import Notification from '../../components/Notification';


const OrderSample = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        document.title = 'LAX | Order Sample';
    }, []);

    const validateEmail = (email: string) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const onSubmit = async (data: any) => {
        setLoading(true);
        setError('');
        setSuccess(false);

        const { name, email, phone, address, promocode, instagram } = data;
        console.log(data);
        const lead: Lead = {
            name,
            instagram,
            email,
            phone,
            address,
            promocode,
            contact_reason: 'sample',
        };
        try {
            await createLead(lead);
            setSuccess(true);
            reset();
        } catch (err) {
            // Promocode does not exist.
            if ((err as any).response?.data?.promocode === 'Promocode does not exist.') {
                setError('Promocode does not exist.');
            } else {
                setError('Server is down, please try again later.');
            }

        }
        setLoading(false);

    }

    return (
        <div id="order-sample">
            <h2>Get your free sample</h2>
            {
                error && <Notification type="failure" message={error} />
            }
            {
                success && <Notification type="success" message="We've got your request!" />
            }
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth>
                        <TextField
                            {...register("promocode", { required: true })}
                            label="Promo Code*"
                            variant="standard"
                            fullWidth
                            margin="normal"
                        />
                        {errors.promoCode && <p className="error">Promo code is required</p>}
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            {...register("instagram", { required: true })}
                            label="Instagram*"
                            variant="standard"
                            fullWidth
                            margin="normal"
                        />
                        {errors.promoCode && <p className="error">Promo code is required</p>}
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            {...register("name", { required: true })}
                            label="Name*"
                            variant="standard"
                            fullWidth
                            margin="normal"
                        />
                        {errors.name && <p className="error">Name is required</p>}
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            {...register("email", { required: true, validate: validateEmail })}
                            label="Email*"
                            variant="standard"
                            fullWidth
                            margin="normal"
                        />
                        {errors.email && <p className="error">Valid email is required</p>}
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            {...register("phone", { required: true })}
                            label="Phone*"
                            variant="standard"
                            fullWidth
                            margin="normal"
                        />
                        {errors.phone && <p className="error">Phone number is required</p>}
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            {...register("address", { required: true })}
                            label="Address*"
                            variant="standard"
                            fullWidth
                            margin="normal"
                        />
                        {errors.address && <p className="error">Address is required</p>}
                    </FormControl>

                    <Button text="Submit" loading={loading} />
                </form>
            </div>
    );
};

export default OrderSample;