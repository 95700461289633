import React from 'react';

interface ArrowRightProps {
    fillColor?: string;
}

const ArrowRight: React.FC<ArrowRightProps> = ({ fillColor }) => {
    return (
        <svg width="38" height="10" viewBox="0 0 38 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.4243 5.42427C37.6586 5.18995 37.6586 4.81005 37.4243 4.57574L33.6059 0.757362C33.3716 0.523048 32.9917 0.523047 32.7574 0.757362C32.523 0.991677 32.523 1.37158 32.7574 1.60589L36.1515 5L32.7574 8.39412C32.523 8.62843 32.523 9.00833 32.7574 9.24264C32.9917 9.47696 33.3716 9.47696 33.6059 9.24264L37.4243 5.42427ZM-5.24537e-08 5.6L37 5.6L37 4.4L5.24537e-08 4.4L-5.24537e-08 5.6Z" fill={fillColor || "black"} />
        </svg>
    );
};

export default ArrowRight;
