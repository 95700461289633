import React from 'react';
import Arrow from './assets/Arrow';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    loading?: boolean;
    fillColor?: string;
}
const Spinner = () => {
    return (
        <div className="spinner">
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

const Button: React.FC<ButtonProps> = ({ onClick, text, loading, fillColor, ...props }) => {
    return (
        <button onClick={onClick} {...props} disabled={loading}>
            {loading ? <Spinner /> : <p style={{color: fillColor}}>{text}</p>}
            <Arrow fillColor={fillColor} />
        </button>
    );
};

export default Button;