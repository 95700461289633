import React, { useState, useEffect } from 'react';
import SolutionIMG from './assets/solution.png';

const options = [
    'peeling or fading polish',
    'complicated return processes',
    'limited product choices',
    'unresponded supplier calls',
    'missed business opportunities'
];

const Solution: React.FC = () => {
    const [currentOption, setCurrentOption] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentOption((prevOption) => (prevOption + 1) % options.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div id='solution'>
            <div className="content">
                <div className="text">
                    <h2>Ultimate Solution</h2>
                    <p>Gel polish products that offer a wide range of colors without compromising on quality or longevity</p>
                </div>
                <div className="image">
                    <img src={SolutionIMG} alt="nail polish bottle" />
                </div>
            </div>
            <div className="nomore">
                <p className="nomore-head">
                    no more...
                </p>
                <div className="nomore-item">
                    <p className="fade-in-out">{options[currentOption]}</p>
                </div>
            </div>
        </div>
    );
};

export default Solution;