import LogoWhite from './assets/logo-white.svg';
import Button from './Button';
import InstagramSVG from './assets/insta.svg';
import EtsySVG from './assets/etsy.svg';
import PinterestSVG from './assets/pinterest.svg';


type LinkProps = {
    href: string;
    text: string;
}

const Link = ({ href, text }: LinkProps) => {
    return (
        <a href={href}>{
            text
        }</a>
    );
}

const Footer = () => {

    const onPhoneNumClick = () => {
        window.open('tel:+13013999999');
    }
    const onEmailClick = () => {
        window.open('mailto:info@lax.gel');
    }
    return (
        <footer>
            <div className="content">
                <div className="top">
                    <div className="left">
                        
                        <p className="contact">
                            If you have any questions, feel free to reach out to us directly at:
                        </p>
                        <Button text='+1 301 399 9999' onClick={onPhoneNumClick} fillColor="#FFF"/>
                        <Button text='info@lax.gel' onClick={onEmailClick} fillColor="#FFF" />
                        <div className="social">
                            <a href="https://www.instagram.com/laxgel" target="_blank" rel="noopener noreferrer">
                                <img src={InstagramSVG} alt="Instagram" />
                            </a>
                            <a href="https://www.etsy.com/shop/LAXGL?ref=shop-header-name&listing_id=1735507792&from_page=listing" target="_blank" rel="noopener noreferrer">
                                <img src={EtsySVG} alt="Etsy" />
                            </a>
                            <a href="https://id.pinterest.com/laxgel/_created" target="_blank" rel="noopener noreferrer">
                                <img src={PinterestSVG} alt="Pinterest" />
                            </a>
                        </div>
                    </div>
                    <div className="right">
                        <div className="links">
                            <div className="column">
                                <Link href="/about" text="About Us" />
                                <Link href="/#bestsellers" text="Quick Shop & Save" />
                                <Link href="/#faq" text="FAQ" />
                            </div>
                            <div className="column">
                                <Link href="/#working" text="Working with us" />
                                <Link href="/#buildyourbrand" text="Build Your Brand" />
                                <Link href="/#referral" text="Referral Rewards" /></div>                            
                            <div className="column">
                                <Link href="/#testimonials" text="Testimonials" />
                                <Link href="/#bestsellers" text="Bestsellers" />
                            </div>
                            
                        </div>
                        <img src={LogoWhite} alt="Logo" />
                    </div>
                </div>
                <div className="bottom">
                    <a href="https://lax.gl/policies/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    <a href="https://verdix.io" target="_blank" rel="noopener noreferrer">Created by VERDIX | not just website</a>
                    <a href="https://lax.gl/policies/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                </div>
            </div>
        </footer>
    );
}
    
export default Footer;