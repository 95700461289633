import Button from '../../components/Button';
import BestsellerGel from './assets/bestsellersgel.png';
import BestsellerHard from './assets/bestsellershard.png';
import ReferralProgramIMG from './assets/hard.png';
import ReferralProgram2IMG from './assets/hard3.png';
import ReferralProgram3IMG from './assets/buildyourbrand.png'
import { useUserSelection } from '../../UserSelectionContext';
import { redirectToShopify } from '../../utils';


const Bestsellers = () => {

    const { setSelection } = useUserSelection();

    const handleClick = () => {
        setSelection('sample');

        // Navigate to the contact section and scroll smoothly into view
        const contactElement = document.getElementById('contact');
        if (contactElement) {
            contactElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleReferralClick = () => {
        setSelection('referral');

        // Navigate to the contact section and scroll smoothly into view
        const contactElement = document.getElementById('contact');
        if (contactElement) {
            contactElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <div id='bestsellers'>
            <div className="headers">
                <h2>Bestsellers</h2>
                <p className="subheader">Empower your business with our premium products and tailored business offers</p>
            </div>


            <div className="products">
                <div className="product gel">
                    <div className="image" >
                        <img className='deco' src={BestsellerGel} alt="gel polish bottle" />
                    </div>
                    <div className="text">
                        <h3>Gel Polish</h3>
                        <p className='description'>Premium gel polish ensuring flawless and long-lasting results</p>
                        <Button text="Free Samples" onClick={handleClick} />
                    </div>
                </div>
                <div className="product hard">
                    <div className="image" >
                        <img className='deco' src={BestsellerHard} alt="gel polish bottle" />
                    </div>
                    <div className="text">
                        <h3>Hard Gel (Builder)</h3>
                        <p className='description'>High-quality hard gel designed for professional use, providing durability and strength</p>
                        <Button text="Shop Now" onClick={redirectToShopify} />
                    </div>
                </div>
            </div>

            <h2 className='offers'>Offers</h2>

            <div className="services">
                
                <div className="referral">
                    <div className="top">
                        <img src={ReferralProgramIMG} alt="referral program" />
                        <h3>Referral Program</h3>
                        <p className='description'>Earn rewards with our referral program, bringing benefits to you and your network</p>
                    </div>
                    <Button text="Contact now" onClick={handleReferralClick} />
                </div>
                <div className="bybp">
                    <h3>Build Your Brand Program</h3>
                    <div className="content">
                            <div className='pic'>
                                <img src={ReferralProgram3IMG} alt="build your brand program" />
                            </div>
                        

                        <div className="with-text">
                            <p className='description'>Customize your products with your brand logo and packaging</p>
                            <img src={ReferralProgram2IMG} alt="build your brand program" />
                        </div>
                    </div>
                </div>
            </div>
         
        </div>
    );
}

export default Bestsellers;