import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of the context state
interface UserSelectionContextType {
    selection: string | null;
    setSelection: (selection: string | null) => void;
}

// Create the context with default values
const UserSelectionContext = createContext<UserSelectionContextType | undefined>(undefined);

// Create a provider component
export const UserSelectionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [selection, setSelection] = useState<string | null>(null);
    return (
        <UserSelectionContext.Provider value={{ selection, setSelection }}>
            {children}
        </UserSelectionContext.Provider>
    );
};

// Create a custom hook to use the context
export const useUserSelection = (): UserSelectionContextType => {
    const context = useContext(UserSelectionContext);
    if (!context) {
        throw new Error('useUserSelection must be used within a UserSelectionProvider');
    }
    return context;
};
