import axios from 'axios';

// Base API URL
// const API_URL = ''; // Ensure this is correct
let API_URL = 'https://aabovian.pythonanywhere.com/'; // Ensure this is correct
if (window.location.hostname === 'localhost') {
    API_URL = 'http://127.0.0.1:8000/';
}
// Endpoint for creating leads
const lead_url = 'leads/'; // Ensure this matches your backend endpoint

export interface Lead {
    name?: string;
    email?: string;
    phone?: string;
    message?: string;
    address?: string;
    promocode?: string; // Promocode should be a string
    contact_reason?: string; // Ensure this matches the allowed values on the backend
    instagram?: string;
}

export const createLead = async (lead: Lead) => {
    try {
        try {
            // @ts-ignore
            heap.identify(lead.email);    
            // @ts-ignore
            heap.addUserProperties({
                email: lead.email,
                name: lead.name,
                phone: lead.phone,
                address: lead.address,
                instagram: lead.instagram,
            });
        } catch (error) {
            console.error('Error identifying user:', error);
        }
        // POST request to create a lead
        const response = await axios.post(`${API_URL}${lead_url}`, lead, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        
        return response.data;
    } catch (error) {
        console.error('Error creating lead:', error);
        throw error;
    }
};
