import React, { useState, useEffect } from 'react';

type NotificationProps = {
    type: 'success' | 'failure';
    message: string;
};

const Notification: React.FC<NotificationProps> = ({ type, message }) => {
    const [closed, setClosed] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setClosed(true);
        }, 10000); // Hide after 10 seconds

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const handleClose = () => {
        setClosed(true);
    };

    if (closed) {
        return null; // Don't render the notification if it's closed
    }

    return (
        <div className={`notification ${type}`}>
            <span>{message}</span>
            <button className="close-button" onClick={handleClose}>
                X
            </button>
        </div>
    );
};

export default Notification;