import React, { useEffect } from "react";

import Button from "../../components/Button";
import HeroIMG from './assets/hero.png';
import HeroDeskIMG from './assets/hero-desk.png';
import { useUserSelection } from '../../UserSelectionContext';
import ScrollReveal from "scrollreveal";

const DELAY = 200;
const DURATION = 1000;
const DISTANCE = '20px';

const Hero: React.FC = () => {
    const { setSelection } = useUserSelection();

    const handleClick = () => {
        setSelection('sample');

        // Navigate to the contact section and scroll smoothly into view
        const contactElement = document.getElementById('contact');
        if (contactElement) {
            contactElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    useEffect(() => {
        ScrollReveal().reveal('.subheader', {
            delay: DELAY,
            duration: DURATION,
            origin: 'left',
            distance: DISTANCE,
        });
        ScrollReveal().reveal('#hero button', {
            delay: DELAY,
            duration: DURATION,
            origin: 'right',
            distance: DISTANCE,
        });
        ScrollReveal().reveal('#bestsellers .text button', {
            delay: DELAY,
            duration: DURATION,
            origin: 'right',
            distance: DISTANCE,
        });
        ScrollReveal().reveal('#bestsellers .services button', {
            delay: DELAY,
            duration: DURATION,
            origin: 'right',
            distance: DISTANCE,
        });
        ScrollReveal().reveal('h1', {
            delay: DELAY,
            duration: DURATION,
            origin: 'top',
            distance: DISTANCE,
        });
        ScrollReveal().reveal('h2', {
            delay: DELAY,
            duration: DURATION,
            origin: 'left',
            distance: DISTANCE,
        });
    }, []);


    return (
        <div id='hero'>
            <h1>Transform Your Salon with LAX</h1>
            <div className="content">
                <div />
                <p className="subheader">Premium nail products delivered swiftly for ultimate client satisfaction</p>
                <Button text="Free samples" onClick={handleClick} />
            </div>
            <img className="decor" src={HeroIMG} alt="gel polish brushes" />
            <img className="decor-desk" src={HeroDeskIMG} alt="gel polish brushes" />

        </div>
    );
};

export default Hero;
