import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import AboutIMG from './assets/about.png';
import AboutIMG2 from './assets/about2.png';


const benefits = [
    {
        title: 'High - Quality Products',
        description: [
            'durable gel polish',
            'and hard gel for',
            'flawless results'
        ]
    },
    {
        title: 'Fast Delivery',
        description: [
            'swift orders',
            'to minimize',
            'downtime'
        ]
    },
    {
        title: 'Dependable Partnership',
        description: [
            'dedicated agent',
            'for every',
            'salon'
        ]
    }
]

const About = () => {
    const navigate = useNavigate();
    const onClick = () => navigate('/about');
    return (
        <div id="about">
            <h2>About us</h2>
            <div className="desktop">            
                <div className="content">
                    <img className='deco' src={AboutIMG} alt="scientist tests samples" />
                    <div className="text">
                        <div className="paragraphs">
                            <p>LAX is your dedicated source for premium gel polish and hard gel products, delivering professional-grade nail solutions worldwide from our trusted factory</p>
                            <p>Founded on a vision of superior nail products, LAX has upheld a {'>'}10 years track record of zero returns or complaints.</p>
                        </div>
                        <div className="slogan">
                            <p>choosing LAX =
                                choosing excellence</p>
                        </div>
                    </div>
                    <div className="cta">
                        <Button text="Read more" onClick={onClick} />
                        <img className='deco cta' src={AboutIMG2} alt="nail polish bottles" />
                    </div>
              </div>

            </div>
            <div className="mobile">
                <div className="content">
                    <div className="left">
                        <div className="text">
                            <div className="paragraphs">
                                <p>LAX is your dedicated source for premium gel polish and hard gel products, delivering professional-grade nail solutions worldwide from our trusted factory</p>
                                <p>Founded on a vision of superior nail products, LAX has upheld a {'>'}10 years track record of zero returns or complaints.</p>
                            </div>

                        </div>
                      
                    </div>
                    <div className="right">
                        <img className='deco' src={AboutIMG} alt="scientist tests samples" />
                        <Button text="Read more" onClick={onClick} />
                    </div>
                </div>
                <div className="bottom">
                    <img className='deco cta' src={AboutIMG2} alt="nail polish bottles" />
                    <div className="slogan">
                        <p>choosing LAX = <br />
                            choosing excellence</p>
                    </div>
                </div>


            </div>
            <div className="benefits">
                {benefits.map((benefit, index) => (
                    <div key={index} className="benefit">
                        <h4>{benefit.title}</h4>
                        <ul>
                            {benefit.description.map((desc, index) => (
                                <li key={index}>{desc}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
        
    );
}

export default About;
    