import { useState } from 'react';
import FAQIMG from './assets/faq.png';
import CrossSVG from './assets/cross.svg';
import MinusSVG from './assets/minus.svg';

const faqs = [
    {
        title: "What about pricing and discounts?",
        description: "We offer a loyalty program where we tailor deals specifically for you, ensuring you get unbeatable offers.",
    },
    {
        title: "What's your experience in the market?",
        description: "We've been in the industry for 10 years with zero returns or complaints.",
    },
    {
        title: "Who do you work with?",
        description: "We collaborate with clients in USA, Qatar, Dubai, Slovenia, England, Canada, and more.",
    },
    {
        title: "Do you offer hard gel?",
        description: "Yes, we offer the top-tier hard gel line with 15 vibrant colors.",
    },
    {
        title: "How many colors do you offer?",
        description: "We have 450 gel polish colors and 100 rubber base colors available!",
    },

]

const FAQ = () => {
    const [activeList, setActiveList] = useState<number[]>([]);

    const handleClick = (index: number) => {
        if (activeList.includes(index)) {
            setActiveList(activeList.filter((i) => i !== index));
        } else {
            setActiveList([...activeList, index]);
        }
    };

    return (
        <div id="faq">
            <div className="container">
                <div className="left">
            <img src={FAQIMG} alt="FAQ" />
                </div>
                <div className="right">
                                        <h2>FAQ</h2>
                    <p className="subheader">Get answers to common questions about LAX products and services.</p>
                    <div className="items">
                        {faqs.map((faq, i) => (
                            <div
                                key={i}
                                className={`item ${activeList.includes(i) ? 'active' : ''}`}
                                onClick={() => handleClick(i)}
                            >
                                <div className="question-box">
                                    <p className="question">{faq.title}</p>
                                    {
                                        activeList.includes(i) ? <img src={MinusSVG} alt="Minus" /> : <img src={CrossSVG} alt="Cross" />
                                    }
                                </div>
                                
                                <p className={`answer ${activeList.includes(i) ? 'active' : ''}`}>{faq.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;