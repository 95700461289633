import StarSVG from './assets/star.svg';

const reviews = [
    {
        name: 'Laura',
        header: 'Clients keep asking for LAX',
        body: `It's really tough to find a good gel polish supplier in the US since so many have bad quality products. Luckily, I gave LAX a shot and I’m so glad I did. They deliver super fast and the quality is top-notch. The rubber base is amazing to work with, and now clients keep asking for LAX whenever they come in for their nails.`
    },
    {
        name: 'Paula',
        header: `I haven’t heard a single review lower than 5 stars`,
        body: `After getting more than 10 negative feedbacks from my clients, I stopped working with my previous gel polish supplier.I found LAX on Instagram, tried their samples, and was really amazed by their gel polishes.Now, we work together, and I haven’t heard a single review lower than 5 stars.It’s amazing!`
    }
]

const Testimonials = () => {
    return (
        <div id="testimonials">
            <h2>Testimonials</h2>
            <div className="reviews">
                {reviews.map((review, i) => (
                    <div key={i} className="review">
                        <div className="header">

                            <h3>{review.name}</h3>
                            <div className="stars">
                                <img src={StarSVG} alt="star" />
                                <img src={StarSVG} alt="star" />
                                <img src={StarSVG} alt="star" />
                                <img src={StarSVG} alt="star" />
                                <img src={StarSVG} alt="star" />
                            </div>
                        </div>
                        <div className="body">
                            <p className='header'>{review.header}</p>
                            <p className='pbody'>{review.body}</p>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    );
}
    
export default Testimonials;