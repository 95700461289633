import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import About from "./About";
import Bestsellers from "./Bestsellers";
import Contact from "./Contact";
import FAQ from "./FAQ";
import Hero from "./Hero";
import Solution from "./Solution";
import './styles.scss';
import Testimonials from "./Testimonials";
import WorkingWithUs from "./Working";


const Container = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }
        }
    }, [location]);
    return (
        <div id='landing'>
            <Hero />
            <Solution />
            <Bestsellers />
            <About />
            <Testimonials />
            <WorkingWithUs />
            <FAQ />
            <Contact />
        </div>
    );
};

export default Container;