import './about.scss';

import MissionIMG from './assets/mission.png';
import Mission2IMG from './assets/mission2.png';
import Mission3IMG from './assets/mission3.png';
import StoryIMG from './assets/story.png';
import ValuesIMG from './assets/values.png';
import Values2IMG from './assets/values2.png';
import WhoIMG from './assets/who.png';
import WhyIMG from './assets/why.png';
import Why2IMG from './assets/why2.png';
import Why3IMG from './assets/why3.png';
import WhoWeAre from './assets/WhoWeAre.png';

import Join from './Join';
import { useEffect } from 'react';

const values = [
    {
        title: 'Quality',
        text: 'We ensure that every product meets the highest standards.'
    },
    {
        title: 'Reliability',
        text: 'Fast, dependable delivery you can count on.'
    },
    {
        title: 'Professionalism',
        text: 'Products designed specifically for professional use.'
    },
    {
        title: 'Innovation',
        text: 'Continuously improving and expanding our product range.'
    },
    {
        title: 'Customer Satisfaction',
        text: 'We are committed to exceeding our customers\' expectations.'
    }
]

function WhoSection() {
    return (
        <>
            <div id="who">
                <h1>Who We Are</h1>
                <div className="content">
                    <img src={WhoIMG} className='deco' alt="Who" />
                    <div className="text">
                        <img src={WhoWeAre} className='header' alt="Who We Are" />
                        <div className="desktop">
                            <p className="body1">LAX is a dedicated provider of high-quality gel polish and hard gel products, sourced directly from a trusted factory.</p>
                            <p className="body1">Founded with the mission to bring professional-grade nail products to the US and global markets, we pride ourselves on our impeccable track record and commitment to excellence.</p>
                        </div>
                    </div>
                </div>

            <div className="mobile text">
                <p className="body2">LAX is a dedicated provider of high-quality gel polish and hard gel products, sourced directly from a trusted factory.</p>
                <p className="body2">Founded with the mission to bring professional-grade nail products to the US and global markets, we pride ourselves on our impeccable track record and commitment to excellence.</p>
                </div>
            </div>

        </>
    );
}

function StorySection() {
    return (
        <div id="story">
            <div className="desktop">
                <div className="text">
                    <h2>Our Story</h2>
                    <div className="points">
                        <div className="top">
                            <div className="cont">
                                <p className="body3">Our journey began with a vision to offer superior nail products that stand the test of time. </p>
                            </div>

                            <div className="numbers">
                                <p className="number">120+</p>
                                <p className="label">satisfied customers</p>
                            </div>

                        </div>
                        <div className="bottom">
                            <p className="body3">Over the past decade, our products have consistently delivered exceptional results, with zero returns or complaints.</p>
                            <div className="numbers">
                                <p className="number">10Y+</p>
                                <p className="label">On<br />the market</p>
                            </div>
                            <p className="body3">In 2024, we launched the LAX brand to bring these trusted products to the US market, offering fast delivery and unparalleled quality to nail salons and professionals.</p>
                        </div>
                    </div>
                </div>
                <img src={StoryIMG} alt="spilled bottle gel polish" />
            </div>
            <div className="mobile content">
                <div className="left">
                    <h2>Our Story</h2>
                    <p className="body3">Our journey began with a vision to offer superior nail products that stand the test of time. </p>
                    <div className="points">
                        <div className="numbers">
                            <p className="number">120+</p>
                            <p className="label">satisfied customers</p>
                        </div>
                        
                    </div>
                    <p className="body3">In 2024, we launched the LAX brand to bring these trusted products to the US market, offering fast delivery and unparalleled quality to nail salons and professionals.</p>

                    
                </div>
                <div className="right">
                    <img src={StoryIMG} alt="spilled bottle gel polish" />
                    <p className="body3">Over the past decade, our products have consistently delivered exceptional results, with zero returns or complaints.</p>
                    <div className="points">
                        <div className="numbers">
                            <p className="number">10Y+</p>
                            <p className="label">On<br />the market</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

function ValuesSection() {
    return (
        <div id="values">
            <div className="text">
                <h2>Our Values</h2>
                <div className="steps">
                    {values.map((value, index) => (
                        <div key={index} className="step">
                            <h4>{value.title}</h4>
                            <p className='body3'>{value.text}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="imgs">
                <img className='people' src={Values2IMG} alt="people look at report" />
                <img className='bottle' src={ValuesIMG} alt="gel polish bottle" />

            </div>
        </div>
    );
}

function WhySection() {
    return (
        <div id="why">
            <div className="text">
                <h2>Why Choose Us</h2>
                <div className="item">
                    <img src={Why2IMG} alt="Why2" />
                    <p className="body3">At LAX, we understand the importance of having reliable and high-quality products for your salon or personal use.</p>
                </div>
                <div className="item">
                    <img src={Why3IMG} alt="Why3" />
                    <p className="body3">Our fast delivery service, exceptional product quality, and customer-centric approach set us apart from the competition. Join us and experience the difference that LAX brings to the nail industry.</p>
                </div>
            </div>
                <img className='deco' src={WhyIMG} alt="Why" />
        </div>
    );
}

function MissionSection() {
    return (
        <div id="mission">
            <div className="text">
                <h2>Our Mission</h2>
                <div className="item">
                    <p className="body3">We strive to make every customer feel confident and satisfied, ensuring their nails look flawless every time.</p>

                    <img src={Mission2IMG} alt="Mission2" />
                </div>
            </div>
            <div className="right">
                <div className="item">
                    <img src={MissionIMG} alt="Mission" />
                    <p className="body3">We strive to make every customer feel confident and satisfied, ensuring their nails look flawless every time.</p>
                </div>
                <img className='sep' src={Mission3IMG} alt="Mission3" />
            </div>
        </div>
    );
}

function About() {
    useEffect(() => {
        document.title = 'LAX | About';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <WhoSection />
            <StorySection />
            <ValuesSection />
            <WhySection />
            <MissionSection />
            <Join />
        </>
    );
}

export default About;