import WorkingWithUsIMG from './assets/workingwithus.png';
import QuickShopIMG from './assets/quickshop.png';
import ReferralProgram from './assets/referralprogram.png';
import ReferralProgram3 from './assets/referralprogram3.png';
import BuildYourBrand3 from './assets/buildyourb.png';
import BuildYourBrand2 from './assets/buildyb2.png';
import Button from '../../components/Button';

import { useUserSelection } from '../../UserSelectionContext';

const referral = [
    {
        title: "Sign Up",
        description: "Email info@lax.gl",
    },
    {
        title: "Receive Promo Code",
        description: "Offer 15% discount to customers.",
    },
    {
        title: "Earn Commission",
        description: "Get 15% for each sale via your code.",
    },
]

const shop = [
    {
        title: "Visit Online Shop",
        description: "Browse and select products.",
    },
    {
        title: "Delivery",
        description: "Same - day in Los Angeles, mail for other states.",
    },
    {
        title: "Enjoy Products",
        description: "Experience high quality; benefit from bulk order discounts and referral program.",
    },
]
const buildYourBrand = [
    {
        title: "Contact Us",
        description: "Discuss brand-building interest.",
    },
    {
        title: "Custom Design",
        description: "Collaborate on bottle design.",
    },
    {
        title: "Production",
        description: "Use LAX gel polishes or rubber base.",
    },
    {
        title: "Launch",
        description: "Receive and sell your branded products.",
    },
]



const WorkingWithUs = () => {
    const { setSelection } = useUserSelection();

    const handleReferralClick = () => {
        setSelection('referral');
        const contactElement = document.getElementById('contact');
        if (contactElement) {
            contactElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleBuildYourBrandClick = () => {
        setSelection('build-your-brand');
        const contactElement = document.getElementById('contact');
        if (contactElement) {
            contactElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleQuickShopClick = () => {
        setSelection('sample');
        const contactElement = document.getElementById('contact');
        if (contactElement) {
            contactElement.scrollIntoView({ behavior: 'smooth' });
        }
    }


    return (
        <div id="working">
            <div className="container">
                <div className="mobile">
                    <h2>Working with us</h2>
                    <p className="subheader">Explore seamless collaboration with LAX through orders, referrals, and custom branding.</p>
                </div>
                <div className="left">
                    <div className="desktop">
                        <h2>Working with us</h2>
                        <p className="subheader">Explore seamless collaboration with LAX through orders, referrals, and custom branding.</p>
                    </div>

                    <div className="referral" id='referral'>
                        <img src={WorkingWithUsIMG} alt="Working with us" />
                        <h3>Referral Rewards</h3>
                        <p className='item body2'>Earn money by promoting LAX products through our lucrative referral program.</p>
                        <ul className="steps">
                            {referral.map((r, i) => (
                                <li key={i}>
                                    <p className="number">{i + 1}.</p>
                                    <div className="content-list">
                                        <h4>{r.title}</h4>
                                        <p className='description'>{r.description}</p>
                                    </div>

                                </li>
                            ))}
                        </ul>
                        <Button text="Contact now" onClick={handleReferralClick} />
                        <img src={ReferralProgram3} alt="Referral Program" />
                    </div>
                </div>
                <div className="right">
                    <div className="quickshop">
                        <img src={QuickShopIMG} alt="Quick Shop" />
                        <h3>Quick Shop & Save</h3>
                        <p className='item body2'>Seamlessly order high-quality nail products with exclusive discounts and loyalty rewards.</p>
                        <ul className="steps">
                            {shop.map((s, i) => (
                                <li key={i}>
                                    <p className="number">{i + 1}.</p>
                                    <div className="content-list">
                                        <h4>{s.title}</h4>
                                        <p className='description'>{s.description}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <Button text="Free samples" onClick={handleQuickShopClick} />
                        <img src={ReferralProgram} alt="Referral Program" />
                    </div>

                </div>
            </div>

            <div className="buildyourbrand" id='buildyourbrand'>
                <div className="container">
                    <div className="left">
                        <h3>Build Your Brand</h3>
                        <div className="mobile">
                            <p className='body2'>Collaborate with LAX to create and sell your own branded products.</p>
                        </div>
                        <ul className="steps">
                            {buildYourBrand.map((b, i) => (
                                <li key={i}>
                                    <p className="number">{i + 1}.</p>
                                    <div className="content-list">
                                        <h4>{b.title}</h4>
                                        <p className='description'>{b.description}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="mobile">
                            <Button text="Start now" onClick={handleBuildYourBrandClick} />
                        </div>
                    </div>
                    <div className="right">
                        <div className="desktop">
                            <p className='body2'>Collaborate with LAX to create and sell your own branded products.</p>
                        </div>
                        
                        <div className="imgs">
                            <img src={BuildYourBrand2} alt="Build Your Brand" />
                            <img className='hide' src={BuildYourBrand3} alt="Build Your Brand" />
                        </div>
                        <div className="desktop">
                            <Button text="Start now" onClick={handleBuildYourBrandClick} />
                        </div>
                        
                    </div>
                </div>
            </div>

        </div>
    );
}
    
export default WorkingWithUs;