import { useState } from "react";
import { FormControl, TextField } from "@mui/material";
import Button from "../../components/Button";
import { useForm } from "react-hook-form";
import { createLead } from "../../api/createLead";
import Notification from "../../components/Notification";



const Join = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const onSubmit = async (data: any) => {
        setLoading(true);
        setError('');

        const { name, email, phoneNumber, message } = data;
        const lead = {
            name,
            email,
            phone: phoneNumber,
            message,
            contact_reason: 'other',
        };
        try {
            await createLead(lead);
            setSuccess(true);
            reset();
        } catch (err) {
            setError('Server is down, please try again later.');
        }
        setLoading(false);
    }

    const validateEmail = (email: string) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }


    return (
        <div id="join">
            {
                error && <Notification type="failure" message={error} />
            }
            {
                success && <Notification type="success" message="We've got your request!" />
            }
            <h2>Join the LAX community</h2>
            <div className="content">
                <div className="placeholder" />
                <div className="form-container">
                    <div className="text">
                        <p className="body3">Become part of a community that values quality, speed, and professional excellence.</p>
                        <p className="body3">Whether you're a nail salon owner, a professional nail artist, or a beauty enthusiast, LAX is here to support your journey with products you can trust.</p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl fullWidth>
                            <TextField
                                {...register("name", { required: true })}
                                label="Name*"
                                variant="standard"
                                fullWidth
                                margin="normal"
                                error={!!errors.name}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                {...register("email", { required: true, validate: validateEmail })}
                                label="Email*"
                                variant="standard"
                                fullWidth
                                margin="normal"
                                error={!!errors.email}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                {...register("phoneNumber")}
                                label="Phone Number"
                                variant="standard"
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                {...register("message")}
                                label="Message"
                                variant="standard"
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <Button text="Submit" loading={loading} />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Join;