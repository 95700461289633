import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import ContactIMG from './assets/contact.png';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useForm } from 'react-hook-form';
import { useUserSelection } from '../../UserSelectionContext';
import { createLead, Lead } from '../../api/createLead';
import Notification
    from '../../components/Notification';
const contactReasons = [
    {
        id: 'sample',
        label: 'Free Sample',
        subheader: 'Interested in trying our products? Fill out the form below to receive a free sample.'
    },
    {
        id: 'referral',
        label: 'Referral Program',
        subheader: 'Join our referral program to earn rewards for your business.'
    },
    {
        id: 'build-your-brand',
        label: 'Build Your Brand',
        subheader: 'Customize your products with your brand logo and packaging.'
    },
    {
        id: 'other',
        label: 'Other',
        subheader: 'Please fill out the form below and we will get back to you shortly.'
    },
];


const emailValidation = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}


const Contact = () => {
    const { selection } = useUserSelection();
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [contactReason, setContactReason] = useState('');
    const [subheader, setSubheader] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        if (selection) {
            setContactReason(selection);
            setValue('contactReason', selection);
        }
    }, [selection, setValue]);

    const handleReasonChange = (event: SelectChangeEvent) => {
        setContactReason(event.target.value);
    };

    const onSubmit = async (data: any) => {
        setLoading(true);
        setError('');
        setSuccess(false);

        const { name, email, phoneNumber, message, address, promocode } = data;
        const lead: Lead = {
            name,
            email,
            phone: phoneNumber,
            message,
            address,
            promocode,
            contact_reason: contactReason,
        };
        try {
            await createLead(lead);
            setSuccess(true);
            reset();
        } catch (err) {
            setError('Server is down, please try again later.');
        }
        setLoading(false);

    }

    // Determine the subheader to display
    useEffect(() => {
        const selectedReason = contactReasons.find((reason) => reason.id === contactReason);
        if (selectedReason) {
            setSubheader(selectedReason.subheader);
        } else {
            setSubheader('Select a contact reason to get started');
        }

    }, [contactReason]);

    return (
        <>
            <div className="mobile">
                <div className="right">
                    <img src={ContactIMG} alt="Contact" />
                </div>
            </div>
        <div id="contact">
            {error && <Notification type="failure" message={error} />}
            {success && <Notification type="success" message="We've got your request!" />}
            <div className="left">
                <h2>Get your free sample</h2>
                    <p className="subheader">
                        {subheader}
                    </p>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth>
                        <Select
                            {...register("contactReason", { required: true })}
                            value={contactReason}
                            onChange={handleReasonChange}
                            displayEmpty
                            variant='standard'
                        >
                            <MenuItem value="" disabled>
                                Select a contact reason*
                            </MenuItem>
                            {contactReasons.map((reason) => (
                                <MenuItem key={reason.id} value={reason.id}>
                                    {reason.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.contactReason && <p className="error">Contact reason is required</p>}
                    </FormControl>
                    <TextField
                        {...register("name", { required: true })}
                        label="Name*"
                        error={!!errors.name}
                        helperText={errors.name ? "Please enter your name" : ""}
                        fullWidth
                        variant='standard'

                        margin="normal"
                    />

                    <TextField
                        {...register("phoneNumber", { required: true })}
                        label="Phone Number*"
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber ? "Please enter your phone number" : ""}
                        fullWidth
                        variant='standard'

                        margin="normal"
                    />
                    {contactReason === "sample" && (
                        <>

                            <TextField
                                {...register("address", { required: true })}
                                label="Address*"
                                error={!!errors.address}
                                helperText={errors.address ? "Please enter your address" : ""}
                                fullWidth
                                variant='standard'

                                margin="normal"
                            />
                        </>
                    )}

                    {contactReason !== "sample" && (
                        <>
                        <TextField
                            {...register("email", { required: true, validate: emailValidation })}
                            label="Email*"
                            error={!!errors.email}
                            helperText={errors.email ? "Please enter your email" : ""}
                            fullWidth
                            variant='standard'

                            margin="normal"
                            />
                        </>
                    )}
                    <TextField
                        {...register("message")}
                        label="Message"
                        fullWidth
                        variant='standard'

                        margin="normal"
                    />

                    <Button type="submit" text="Submit" loading={loading} />
                </form>
                </div>
                <div className="desktop">
                    <div className="right">
                        <img src={ContactIMG} alt="Contact" />
                    </div>
                </div>

            </div>
        </>

    );
};

export default Contact;
